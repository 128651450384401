import React from 'react';
import { graphql } from 'gatsby';
import { Trans } from 'gatsby-plugin-react-i18next';
import InstallationLayout from '../../components/Installation/InstallationLayout';
import { DOWNLOAD_WINDOWS_ROUTE } from '../../const/routes.const';
import LinkWrapper from '../../components/common/LinkWrapper';

const InstallationWinPage = (): React.ReactElement => (
  <InstallationLayout
    note={
      <Trans i18nKey="IMPORTANT: Do not change the installation folder. Octo Browser needs to be installed into the default folder for its correct operation.">
        <strong>IMPORTANT:</strong> Do not change the installation folder. Octo Browser needs to be
        installed into the default folder for its correct operation.
      </Trans>
    }
    steps={[
      <Trans i18nKey="Run the downloaded installer (run as administrator by default)" key={1}>
        Run <LinkWrapper external isNative url={DOWNLOAD_WINDOWS_ROUTE}>
        the downloaded installer
      </LinkWrapper> (run as administrator by default)
      </Trans>,
      <Trans key={2}>
        Install Octo Browser into the folder suggested by default (C:\Program Files\Octo Browser).
        Wait for the installation to finish.
      </Trans>,
      <Trans key={3}>All done! You will see the Octo Browser icon on your desktop</Trans>,
    ]}
    title={<Trans>Download Octo Browser for Windows</Trans>}
  />
);

export default InstallationWinPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["installationPage", "installationWinPage", "translation"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
